<template>
  <div class="content">
    <div class="search-attr">
      <div>
        <a-input v-model="params.userName" allowClear size='small' style="width:150px;" placeholder='昵称或userID'></a-input>

        <a-select allowClear v-model="params.type" size='small' style="width:250px;margin:5px;" placeholder='类型刷选'>
          <a-select-option
              v-for="item in riskConfigManageType"
              :key="item.value"
              :value='item.value'>{{ item.name }}
          </a-select-option>
        </a-select>
        <a-button @click="search" size='small' type="primary" style="margin-left:10px;">搜索</a-button>
        <a-button style="margin-left:10px;" @click="openModal()" size='small'>添加黑名单</a-button>
      </div>
    </div>

    <div class="table-content" >
      <a-table @change="changePage" :pagination='pagination' :loading='loading' :columns="columns" :data-source="data" :rowKey="(record,n)=>n">
        <div slot-scope="row" slot="extendContentSlot">
          <div v-if="row.extendValue">{{ row.extendValue }}</div>
        </div>
        <div slot="setup" slot-scope="row">
          <a-popconfirm
              title="确认要执行操作吗?"
              ok-text="Yes"
              cancel-text="No"
              @confirm="handleChangeBlackStatus(row)"
          >
            <a  style='margin-left:5px'>{{ row.markBlackReal ? "取消拉黑" : "拉黑" }}</a>
          </a-popconfirm>
        </div>
      </a-table>
    </div>
    <a-modal centered :width='700' v-model="showModal" @ok='checkForms' @cancel='cancel'>
      <div>
        <a-form-model v-bind="layout" :model='forms' :rules='rules' ref="ruleForm">
          <a-form-model-item label='类型' prop='degree'>
            <a-select
                allowClear
                v-model="forms.type"
                size='small'
                style="width:250px;margin:5px;"
                placeholder='类型'
            >
              <a-select-option
                  v-for="item in riskConfigManageType"
                  :key="item.value"
                  :value='item.value'>{{ item.name }}
              </a-select-option>
            </a-select>
          </a-form-model-item>
          <a-form-model-item label='用户id或ip' prop='content'>
            <a-input placeholder='请输入用户ID或IP' v-model="forms.value"></a-input>
          </a-form-model-item>
          <a-form-model-item label='备注原因' prop='remark'>
            <a-input type="textarea" placeholder='请输入备注原因' v-model="forms.remark"></a-input>
          </a-form-model-item>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import {mapState} from 'vuex'
import {blockTime, riskConfigManageType} from "@/views/cmsPage/dqPlatform/_data";
import untils from "@/untils"
export default {
  data(){
    return {
      untils,
      riskConfigManageType: riskConfigManageType,
      blockTime,
      columns:[
        {title: "ID",dataIndex: "id"},
        {title: "值",dataIndex: "value"},
        {title: "值扩展", align: "center", scopedSlots: { customRender: "extendContentSlot" }},
        {
          title: "类型",
          dataIndex: "type",
          customRender: (text) => {
            const inner = text;
            if (inner == 0) {
              return <span style="color:red">黑名单用户</span>;
            }else if (inner == 5) {
              return <span style="color:red">待观察名单用户</span>;
            }else if (inner == '8') {
              return <span style="color:red">黑名单ip</span>;
            }
          },
        },
        {
          title: "拉黑状态",
          dataIndex: "markBlackReal",
          customRender: (text) => {
            const inner = text;
            if (inner == 0) {
              return <span style="color:gray">待处理</span>;
            }else if (inner == 1) {
              return <span style="color:red">真实拉黑</span>;
            }
          },
        },
        {title: "备注原因",dataIndex: "remark"},
        {title: "操作人",dataIndex: "adminName"},
        {title:'录入时间',align:'center',dataIndex:'createTime'},
        {title: "操作",align: "center",scopedSlots: { customRender: "setup" },},
      ],
      data:[],
      forms:{
        value: undefined,
        type: undefined,
        remark: undefined
      },
      layout:{
        labelCol: { span: 4 },//标签占5份  共24份
        wrapperCol: { span:20 },
      },
      rules:{
        userId:[{ required: true, message: '用户ID', trigger: 'blur' }],
        targetValue:[{ required: true, message: '目标店铺', trigger: 'blur' }],
      },
      showModal:false,
      visible:false,
      params:{
        pageNum:1,
        pageSize:10,
        userName:'',
      },
      pagination:{
        total:0,
        current:1,
        showTotal:(total)=>`共${total}条`,
      },
      loading:false,
      clicked: false,
      hovered: false,
      shopParams:{
        pageNum:1,
        pageSize:6,
        keywordUser: undefined,
        shopIsPaid: 1,
        reviewStatus: 2,
      },
      shopList: [],
      targetValue: undefined
    }
  },

  computed:{
    ...mapState(['ip'])
  },
  async created(){
    await this.getData(this.params)
  },
  methods:{
    /** 搜索结果 */
    handleSearchValue(e) {
    },
    /** 获取店铺列表 */
    async getShopList(userName) {
      let params = JSON.parse(JSON.stringify(this.shopParams))
      params.keywordUser = userName
      const res = await this.axios('/dq_admin/userShopInfo/list',{params: params})
      const {records } = res.data
      if (records && records.length) {
        this.shopList = records.map(el => {
          return {name: el.shopName, value: el.userId}
        })
      }
    },
    async getData(params){
      this.loading = true
      const res = await this.axios('/dq_admin/riskConfig/list',{params:params})
      this.loading = false
      const {records,total} = res.data
      this.data = records
      this.pagination.total = total
    },
    search(){
      this.params.pageNum = 1
      this.getData(this.params)
    },

    changePage(page){
      const {current,pageSize} = page
      this.pagination.current = current
      this.params.pageNum = current
      this.params.pageSize = pageSize
      this.getData(this.params)
    },
    openModal(row) {
      this.showModal = true;
    },
    cancel(){
      this.forms = {rankIcon:'',userId:'', targetValue: ''}
      this.targetValue = undefined
      this.$refs.ruleForm.resetFields();
    },
    checkForms(){
      this.$refs.ruleForm.validate(valid => {
        if(valid){
          this.submit(this.forms)
        }else{
          return false;
        }
      });
    },
    async submit(){
      if (this.targetValue) {
        this.forms.targetValue = this.targetValue.join(',')
      }
      const res = await this.axios.post('/dq_admin/riskConfig/add',this.forms)
      if(res.status == 200){
        const {message} = res
        this.$message.success(message)
        await this.getData(this.params)
        this.showModal = false
        this.cancel()
      }
    },
    deleteWhtieItem(row) {
      const id = row.id
      this.axios("/dq_admin/riskConfig/deleteById", {
        params: { id: id },
      }).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },
    /** 改变拉黑状态 */
    handleChangeBlackStatus(row) {
      const id = row.id
      this.axios("/dq_admin/riskConfig/markBlackById", {
        params: { id: id },
      }).then((res) => {
        this.getData(this.params);
        this.$message.success(res.message);
      });
    },
    handleClickChange(visible) {
      this.clicked = visible;
      this.hovered = false;
    },
  }
}
</script>

<style lang="scss" scoped>
.search-attr{display: flex;justify-content: space-between;padding-bottom:20px;}
.content{display: flex;flex-direction: column;
  .table-content{
    flex:1;
    overflow-y: auto;
  }
}
.take-box{width:100%;max-height:700px;}
.ml-10 {
  margin-left: 10px !important;
}
</style>